/* eslint-disable @next/next/no-img-element */
/* eslint-disable no-console */
import { useMemo, useState } from 'react';
import { NextSeo } from 'next-seo';
import MainLayout from 'layouts/MainLayout';
import useCategoryStore from 'stores/category.store';
import { useQuery } from 'react-query';
import { getDisplayProductSection, getHomepageData } from 'api/homepage';
import { cloneDeep } from 'lodash';
import { WHITELABEL_KEYS } from 'global/constants/whitelabel-keys';
import { CONFIG_SITE } from 'constants/index';
import { useRouter } from 'next/router';
import RegisterPopup from './views/RegisterPopup';
import HeroBanner from './_components/HeroBanner';
import HighlightProductCarousel from './_components/HighlightProductCarousel';
import ProductSectionCarousel from './_components/ProductSectionCarousel';
import RecommendationCarousel from './_components/RecommendationCarousel';
import SeoFooter from './_components/SeoFooter';
import mockHomepageData from '../../../public/mock-data/homepage';
import { parseCookies, setCookie } from 'nookies';
import { Modal } from '@utils/ui';
import { XCircleIcon } from '@heroicons/react/solid';
import { LoadingOutlined } from '@ant-design/icons';
import Image from 'next/image';
import HeroCategoryHorizontal from './_components/HeroCategoryHorizontal';

const HomePage = ({ bannerList }) => {
  const router = useRouter();
  const cookies = parseCookies();
  const isXooply = CONFIG_SITE?.WHITELABEL_KEY === WHITELABEL_KEYS.xooply;

  const { hero_banner, recommendation_banner, middle_banner, highlighted_products } =
    mockHomepageData;

  // const HighlightProductCarousel = dynamic(() => import('./_components/HighlightProductCarousel'), {
  //   ssr: false,
  //   loading: () => <LoadingOutlined />,
  // });

  // const ProductSectionCarousel = dynamic(() => import('./_components/ProductSectionCarousel'), {
  //   ssr: false,
  //   loading: () => <LoadingOutlined />,
  // });

  // const SeoFooter = dynamic(() => import('./_components/SeoFooter'), {
  //   loading: () => <LoadingOutlined />,
  //   ssr: true,
  // });

  // const BuyerTestimonial = dynamic(() => import('./_components/BuyerTestimonial'), {
  //   loading: () => <LoadingOutlined />,
  //   ssr: true,
  // });

  const { categories } = useCategoryStore();
  const [isOpenRegister, setIsOpenRegister] = useState(false);
  const [openModalRegisEwallet, setOpenModalRegisEwallet] = useState(true);

  const onCloseRegisterPopup = () => setIsOpenRegister(false);

  const homepageHighlightedProductsQuery = useQuery(['homepage-highlighted-products'], () =>
    getHomepageData(),
  );

  const highlightedProducts = useMemo(() => {
    const data = cloneDeep(highlighted_products);

    const { data: homepageHighlightedProducts } = homepageHighlightedProductsQuery || {};

    const perLimit = CONFIG_SITE?.HIGHLIGHTED_PRODUCT_PER_SECTION_LIMIT ?? 6;

    const firstHighlight = [...(homepageHighlightedProducts?.data || [])].splice(0, perLimit);
    const secondHightlight = [...(homepageHighlightedProducts?.data || [])].splice(
      perLimit,
      perLimit,
    );

    if (data.length >= 0) {
      data[0].content.products = [...firstHighlight].map((item) => ({
        ...item,
        image: {
          path: item.imagePath,
        },
        sellerStore: {
          storeAddress: {
            cityName: item.storeCity,
            districtName: item.storeDistrict,
          },
        },
      }));
    }

    if (data.length > 1) {
      data[1].content.products = [...secondHightlight].map((item) => ({
        ...item,
        image: {
          path: item.imagePath,
        },
        sellerStore: {
          storeAddress: {
            cityName: item.storeCity,
            districtName: item.storeDistrict,
          },
        },
      }));
    }

    return data;
  }, [homepageHighlightedProductsQuery.data, highlighted_products]);

  const { data: displayProductSectionQuery, isLoading } = useQuery(
    ['display-product-section'],
    () => getDisplayProductSection(),
  );

  const displayProductSectionData = displayProductSectionQuery?.data;

  const productSectionList = CONFIG_SITE.PRODUCT_SECTION_MANAGEMENT
    ? displayProductSectionData
    : null;

  const filteredHighlightedProducts =
    highlightedProducts?.filter((product) =>
      product?.content?.products?.every((p) => p.isProductLimitation !== false),
    ) || [];

  const setSeoTitle = `Situs Pengadaan Barang dan Jasa Terlengkap di Indonesia | ${CONFIG_SITE?.CONTENT?.COMPANY?.BRAND}`;
  const setSeoDesc = `Belanja barang dan jasa sekarang makin mudah di sini. Segera dapatkan suplai barang dan jasa yang berkualitas dari ${CONFIG_SITE?.CONTENT?.COMPANY?.BRAND}! ✓Transparan ✓Aman ✓Terpercaya`;

  const handleCancel = () => {
    const now = new Date();

    setOpenModalRegisEwallet(false);
    setCookie(null, 'flagModal', 'true', {
      maxAge: 24 * 60 * 60,
      path: '/',
    });
  };

  return (
    <div>
      <MainLayout isHomepage>
        <NextSeo
          title={setSeoTitle}
          description={setSeoDesc}
          openGraph={{
            description: setSeoDesc,
            title: setSeoTitle,
          }}
          additionalMetaTags={[
            {
              name: 'ahrefs-site-verification',
              content: 'a7ca62dabc86f08f28ce577146361ecf5b504edab112ec99788f65baefbf6d7f',
            },
          ]}
        />

        <div className="bg-[#FAFAFA]">
          <section className="section-container">
            <HeroBanner data={hero_banner.content} bannerList={bannerList} />
          </section>
        </div>

        <section className="section-container md:mt-5">
          <HeroCategoryHorizontal categories={categories} />
        </section>

        {isLoading && (
          <div className="flex items-center justify-center h-[456px]">
            <LoadingOutlined />
          </div>
        )}

        {!isLoading && productSectionList.length > 0 && (
          <section className="section-container !pt-0">
            <ProductSectionCarousel productSection={productSectionList[0]} />
          </section>
        )}

        {!isLoading && !productSectionList.length && filteredHighlightedProducts.length > 0 && (
          <section className="section-container !pt-0">
            <HighlightProductCarousel productSection={filteredHighlightedProducts[0]} />
          </section>
        )}

        <section className="section-container mt-3">
          <RecommendationCarousel recommendationBanners={recommendation_banner} />
        </section>

        {!isLoading &&
          productSectionList.length > 1 &&
          productSectionList.map(
            (product, index) =>
              index > 0 && (
                <section className="section-container" key={`product-${index + 1}`}>
                  <ProductSectionCarousel productSection={product} />
                </section>
              ),
          )}

        {!isLoading && !productSectionList.length && filteredHighlightedProducts.length > 1 && (
          <section className="section-container">
            <HighlightProductCarousel productSection={filteredHighlightedProducts[1]} />
          </section>
        )}

        {middle_banner && (
          <section className="section-container">
            <div
              role="button"
              tabIndex={0}
              className="flex w-full bg-no-repeat bg-cover cursor-pointer rounded-[10px]"
              onClick={() => router.push(middle_banner?.content?.redirectUrl)}
            >
              <Image
                src={middle_banner.content.image.path}
                alt="Request For Quotation"
                width={1200}
                height={402}
                style={{
                  width: '100%',
                  height: 'auto',
                  borderRadius: '10px',
                }}
              />
            </div>
          </section>
        )}

        {/* {CONFIG_SITE?.CONTENT?.PAGE?.MAINSITE?.HOMEPAGE?.buyer_testimonial && (
        <section className="section-container">
          <BuyerTestimonial
            data={CONFIG_SITE?.CONTENT?.PAGE?.MAINSITE?.HOMEPAGE?.buyer_testimonial}
          />
        </section>
      )} */}

        <RegisterPopup isShow={isOpenRegister} close={onCloseRegisterPopup} />

        {CONFIG_SITE?.MAINSITE?.HOMEPAGE_SEO_TEXT && (
          <div className="mt-6 bg-[#FAFAFA]">
            <SeoFooter />
          </div>
        )}
      </MainLayout>
      {isXooply && (
        <Modal
          width={500}
          open={openModalRegisEwallet && cookies.flagModal === undefined}
          closable={false}
          destroyOnClose
          footer={null}
          className="modal-no-space modal-transparent pb-0"
        >
          <div className="flex items-center justify-center">
            <XCircleIcon
              className="text-black w-6 h-6 min-[500px]:w-8 min-[500px]:h-8 cursor-pointer top-[5px] right-[5px] absolute z-50"
              onClick={() => handleCancel()}
            />
            <Image
              onClick={() => router.push('/register')}
              src={
                CONFIG_SITE?.CONTENT?.PAGE?.MAINSITE?.HOMEPAGE?.popup_banner?.content?.image?.path
              }
              width={500}
              height={500}
              alt="Daftar dan Menangkan Reward Saldo E-Wallet"
              className="cursor-pointer rounded-lg"
              loading="eager"
              priority
            />
          </div>
        </Modal>
      )}
    </div>
  );
};

export default HomePage;
