/* eslint-disable no-param-reassign */
import { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, A11y, Autoplay } from 'swiper';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import Image from 'next/image';

const HeroBanner = ({ data, bannerList }) => {
  const navigationPreviousRef = useRef(null);
  const navigationNextRef = useRef(null);
  const [showNav, setShowNav] = useState(false);
  const hoverTimeout = useRef(null);

  const handleMouseEnter = () => {
    setShowNav(true);
    if (hoverTimeout.current) clearTimeout(hoverTimeout.current);
  };

  const handleMouseLeave = () => {
    hoverTimeout.current = setTimeout(() => {
      setShowNav(false);
    }, 400);
  };

  return (
    <div className="relative" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <Swiper
        style={{
          '--swiper-pagination-color': '#FFFFFF',
          '--swiper-pagination-bullet-inactive-color': '#FFFFFF',
          '--swiper-pagination-bullet-inactive-opacity': '0.44',
          '--swiper-pagination-bullet-size': '10px',
          '--swiper-pagination-bullet-horizontal-gap': '3px',
        }}
        modules={[Navigation, Pagination, A11y, Autoplay]}
        initialSlide={0}
        spaceBetween={2}
        pagination={{ clickable: true }}
        slidesPerView={1}
        className="overflow-hidden h-full rounded-2xl costum-pagination"
        loop={bannerList?.length ? bannerList?.length > 1 : data?.length > 1}
        navigation={{
          prevEl: navigationPreviousRef.current,
          nextEl: navigationNextRef.current,
        }}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        onBeforeInit={(swiper) => {
          swiper.params.navigation.prevEl = navigationPreviousRef.current;
          swiper.params.navigation.nextEl = navigationNextRef.current;
        }}
      >
        {(bannerList?.length ? bannerList : data).map((item, index) => (
          <SwiperSlide key={`hero-banner-${index}`}>
            <a
              href={item.redirectUrl ?? '/'}
              target="_blank"
              className={`w-full h-full main-banner-${index + 1} rounded-lg`}
              rel="noreferrer"
            >
              <div style={{ display: 'flex', flexDirection: 'column' }} className="rounded-lg">
                <Image
                  src={item?.bannerImagePath ?? item?.image?.path}
                  alt={item?.bannerTitle ?? ''}
                  style={{
                    width: '100%',
                    height: 'auto',
                  }}
                  className="!rounded-lg"
                  width={1207}
                  height={422}
                  priority={index === 0}
                />
              </div>
            </a>
          </SwiperSlide>
        ))}
      </Swiper>
      <div
        ref={navigationPreviousRef}
        className={`absolute top-1/2 transform -translate-y-1/2 left-[-10px] z-10 h-[30px] w-[30px] p-[3px] rounded-full bg-white shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)] cursor-pointer transition-opacity duration-300 ease-out ${
          showNav ? 'opacity-100' : 'opacity-0'
        }`}
      >
        <ChevronLeftIcon width={23} height={23} color="red" />
      </div>
      <div
        ref={navigationNextRef}
        className={`absolute top-1/2 transform -translate-y-1/2 right-[-10px] z-10 h-[30px] w-[30px] p-[3px] rounded-full bg-white shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)] cursor-pointer transition-opacity duration-300 ease-out ${
          showNav ? 'opacity-100' : 'opacity-0'
        }`}
      >
        <ChevronRightIcon width={23} height={23} color="red" />
      </div>
    </div>
  );
};

export default HeroBanner;
