import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import Image from 'next/image';

const HeroCategoryHorizontal = ({ categories }) => {
  // Temporary hide "Voucher"
  const categoriesModified = categories?.filter((category) => category?.name !== 'Voucher');
  return (
    <>
      <h6 className="text-[18px] md:text-xl font-bold mb-4">Kategori</h6>
      <SimpleBar className="w-full pb-7 costum-scrollbar">
        <div className="flex justify-evenly gap-0 md:gap-6">
          {categoriesModified?.map((category, index) => (
            <a
              href={category.redirectUrl ? category.redirectUrl : '/'}
              key={index}
              className="flex flex-col gap-1 font-normal text-grayScale05 hover:text-red-500  text-[12px] cursor-pointer w-[100px] h-[115px] md:w-[134px] md:h-[121px] items-center justify-between"
            >
              <div className="w-[100px] h-[65px] md:w-[130px] md:h-[70px] relative justify-center items-center flex m-0">
                {category.imgUrl ? (
                  <Image
                    src={category.imgUrl}
                    alt={category.name}
                    width={65}
                    height={65}
                    style={{ width: '100%', height: 'auto' }}
                    objectFit="contain"
                  />
                ) : null}
              </div>
              <p className="text-center text-ellipsis w-full text-xs md:text-base font-semibold">
                {category.name}
              </p>
            </a>
          ))}
        </div>
      </SimpleBar>
    </>
  );
};

export default HeroCategoryHorizontal;
