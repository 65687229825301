/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @next/next/no-img-element */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-param-reassign */
import { useMemo, useRef, useEffect, useState, useCallback } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, A11y } from 'swiper';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import { startCase, uniqueId } from 'lodash';
import { parseCookies } from 'nookies';
import clsx from 'clsx';
import Link from 'next/link';
import { CONFIG_SITE } from 'constants/index';
import { useAuth } from 'utils/context/auth';
import { currencyFormatter } from 'global/utils/currency.utils';
import Image from 'next/image';
import { Icon } from '@iconify/react';
import { Col, Row } from 'antd';

const ProductSectionCarousel = ({ productSection }) => {
  const data = productSection?.productSectionItems;

  const navigationPreviousRef = useRef(null);
  const navigationNextRef = useRef(null);

  const cookies = parseCookies();
  const { user } = useAuth();
  const { isLogedIn } = user.auth;
  const [isLogin, setIsLogin] = useState(false);
  const [swiperInstance, setSwiperInstance] = useState(null);

  // Once swiperInstance is set and refs are available, reinitialize navigation
  useEffect(() => {
    if (swiperInstance && swiperInstance.navigation) {
      // Update navigation elements with the ref values
      swiperInstance.params.navigation.prevEl = navigationPreviousRef.current;
      swiperInstance.params.navigation.nextEl = navigationNextRef.current;
      swiperInstance.navigation.destroy();
      swiperInstance.navigation.init();
      swiperInstance.navigation.update();
    }
  }, [swiperInstance]);

  const getSellerLocation = (sellerDetail) => {
    if (!sellerDetail) return '';

    const labels = [
      startCase(String(sellerDetail.sellerCity || '').toLowerCase()),
      startCase(String(sellerDetail.sellerProvince || '').toLowerCase())
        .split(' ')
        .map((word) => (word.length <= 3 ? word.toUpperCase() : word))
        .join(' '),
    ];

    return labels.filter((item) => !!item).join(', ');
  };

  const loadPriceValidate = (product) => {
    if (CONFIG_SITE.HIDE_PRODUCT_PRICE_IF_UNAUTHENTICATED) {
      if (isLogin) {
        return `Rp${currencyFormatter(Math.ceil(product?.productPrice))}`;
      }
      return 'Masuk untuk Lihat Harga';
    }
    return `Rp${currencyFormatter(Math.ceil(product?.productPrice))}`;
  };

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    cookies.isLogedIn && setIsLogin(true);
  }, [isLogedIn]);

  const handleSelect = useCallback((product) => (event) => {}, []);

  const slider = useMemo(
    () => (
      <Swiper
        modules={[Navigation, A11y]}
        slidesPerView="auto"
        className="rounded-lg overflow-x-visible"
        navigation={{
          prevEl: navigationPreviousRef.current,
          nextEl: navigationNextRef.current,
        }}
        onSwiper={(swiper) => {
          setSwiperInstance(swiper);
        }}
      >
        {data.map((product, index) => (
          <SwiperSlide key={uniqueId()} style={{ width: 'auto', paddingRight: '0.7rem' }}>
            <Link href={`/product/${product?.productId}`} key={index}>
              <a
                onClickCapture={handleSelect(product)}
                className={`flex h-[286px] w-[190px] rounded-[10px] overflow-hidden border z-10 bg-white mt-1 ${
                  product?.buyerRestricted === true ? 'cursor-not-allowed' : 'cursor-pointer'
                }`}
                onClick={(e) => {
                  if (product?.buyerRestricted === true) e.preventDefault();
                }}
                role="button"
                tabIndex={0}
              >
                <figure className="flex flex-col justify-between w-full ">
                  {product?.buyerRestricted === true && (
                    <img
                      className="absolute top-[50%] translate-y-[-50%] left-[50%] translate-x-[-50%] w-[150px] h-[150px]"
                      src="/assets/illustrations/contact-admin.png"
                      alt="contact-admin"
                    />
                  )}

                  <div
                    className={`flex h-[155px] w-full ${
                      product?.buyerRestricted === true ? 'opacity-50' : ''
                    }`}
                  >
                    <Image
                      src={product?.productImagePath}
                      width={188}
                      height={154}
                      style={{ width: '100%', height: 'auto' }}
                      className="object-contain w-full h-[160px]"
                    />
                  </div>

                  <div
                    className={`flex-1 ${product?.buyerRestricted === true ? 'opacity-50' : ''}`}
                  >
                    <figcaption className="p-[10px] w-full">
                      <p className="mb-1 font-semibold text-grayScale05 min-h-[40px] line-clamp-2 text-sm">
                        {product?.productTitle}
                      </p>
                      <p
                        className={`mb-1 font-[600] text-theme-primary-main line-clamp-1 leading-[30px] tracking-[0.2px ${
                          CONFIG_SITE.HIDE_PRODUCT_PRICE_IF_UNAUTHENTICATED && !isLogin
                            ? 'text-xl'
                            : 'text-xl'
                        }`}
                      >
                        {loadPriceValidate(product)}
                      </p>
                      <div className="flex flex-row items-center space-x-2 h-[32px]">
                        <Icon
                          icon="heroicons-solid:truck"
                          width="17"
                          height="17"
                          className="text-greenSuccess"
                        />
                        <p className="text-gray-600 break-words text-ellipsis w-full line-clamp-2 text-xs">
                          {getSellerLocation(product?.sellerDetail)}
                        </p>
                      </div>
                    </figcaption>
                  </div>
                </figure>
              </a>
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    ),
    [data],
  );

  return (
    <div>
      <div className="flex mt-3 mb-[9px] justify-between items-center">
        <h6 className="text-[18px] md:text-xl font-semibold">{productSection?.title}</h6>
        <Link href={productSection?.directLink}>
          <a className="text-base text-theme-primary-main hover:underline">Lihat Lainnya</a>
        </Link>
      </div>
      <Row vertical>
        <Col>
          <div className="!h-[292px] !w-[188px] rounded-[10px] hidden md:block">
            <Image
              src={productSection?.imagePath}
              alt="banner"
              width={188}
              height={292}
              className="bg-gradient-to-r from-cyan-500 to-blueInfo h-full object-cover object-center w-full rounded-[10px]"
            />
          </div>
        </Col>
        <Col>
          <div className="pl-[10px] w-auto md:w-[540px] lg:w-[700px] xl:w-[955px] 2xl:w-[1015px] costum-nav">
            {slider}
            <div
              ref={navigationPreviousRef}
              className={clsx([
                data?.length >= 5 ? 'hidden md:block' : 'hidden',
                'absolute top-[48%] left-[-9px] z-10 h-[30px] w-[30px] p-[3px] rounded-full bg-white shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)] cursor-pointer',
              ])}
            >
              <ChevronLeftIcon width={23} height={23} color="red" />
            </div>
            <div
              ref={navigationNextRef}
              className={clsx([
                data?.length >= 5 ? 'hidden md:block' : 'hidden',
                'absolute top-[48%] right-[-9px] z-10 h-[30px] w-[30px] p-[3px] rounded-full bg-white shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)] cursor-pointer ',
              ])}
            >
              <ChevronRightIcon width={23} height={23} color="red" />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ProductSectionCarousel;
